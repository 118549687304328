export function GenArea() {

    let PADDING_MIN = 1
    let padding_number = 0;
    let newArr = [];
    let paddingArr = [];
    let newArrPadding = [];
    let model_number = 13;

    const randomArray = (length, max) => [...new Array(length)].fill().map(() => Math.round(Math.random() * max))

    do {
        newArr = [];
        paddingArr = [];
        newArrPadding = [];
        padding_number = 0;

        var arr = randomArray(6 * 8, model_number)
        //console.log(arr)

        var arr_padding = randomArray(6 * 8, model_number)
        //console.log(arr_padding)

        while (arr.length) newArr.push(arr.splice(0, 6));


        while (arr_padding.length) newArrPadding.push(arr_padding.splice(0, 6));

        // console.log("------------newArr------------");
        // console.log(newArr);
        // console.log("-------------newArrPadding-----------");
        // console.log(newArrPadding);

        paddingArr = newArrPadding.map(function (arr) {
            return arr.slice();
        });

        // console.log("-------------paddingArr-----------");
        // console.log(paddingArr);
        // console.log("-------------END paddingArr-----------");

        // create padding for big image
        for (var i = 0; i < paddingArr.length; i++) {
            for (var j = 0; j < paddingArr[i].length; j++) {
                //console.log(paddingArr[i][j]);
                // keep 1 only for big area
                if (paddingArr[i][j] != 1) {
                    paddingArr[i][j] = 0
                }
                // remove number at border
                if ((i == (paddingArr.length - 1)) || (j == (paddingArr[i].length - 1))) {
                    paddingArr[i][j] = 0
                }
            }
        }

        // change to be padding
        for (var i = 0; i < paddingArr.length; i++) {
            for (var j = 0; j < paddingArr[i].length; j++) {
                //console.log(paddingArr[i][j]);
                if (paddingArr[i][j] != 1) {
                    paddingArr[i][j] = 0
                }
            }
        }

        // mark padding
        for (var i = 0; i < paddingArr.length; i++) {
            for (var j = 0; j < paddingArr[i].length; j++) {
                //console.log(paddingArr[i][j]);
                if (paddingArr[i][j] == 1) {
                    // check neighbor
                    if ((paddingArr[i][j + 1] != 1) &&
                        (paddingArr[i + 1][j] != 1) &&
                        (paddingArr[i + 1][j + 1] != 1) &&
                        (paddingArr[i][j + 1] != 2) &&
                        (paddingArr[i + 1][j] != 2) &&
                        (paddingArr[i + 1][j + 1] != 2)
                    ) {
                        paddingArr[i][j] = 1
                        paddingArr[i][j + 1] = 2
                        paddingArr[i + 1][j] = 2
                        paddingArr[i + 1][j + 1] = 2
                        padding_number = padding_number + 1

                    }
                    else {
                        paddingArr[i][j] = 0
                    }

                }
            }
        }

    } while (!(padding_number > PADDING_MIN))


    // console.log("------newArr-----");
    // console.log(newArr);
    // console.log("------paddingArr------");
    // console.log(paddingArr);
    // console.log("------padding_number-------");
    // console.log(padding_number);

    return [newArr, paddingArr]
}
import React, { useState } from "react";
import Sketch from "react-p5";
import { GenArea } from "./GenArtUtil";

function Gen4PointBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {

  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd; 
  let color_3 = color_3rd; 
  let color_4 = color_4th; 
  //let color_5 = color_5th;

  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]
  // box_size = unit_size / 2
  let circle_diameter = unit_size / 3
  let center_circle = unit_size / 4
  let start_point_circle_x = center_circle - (circle_diameter / 3) + x
  let start_point_circle_y = center_circle - (circle_diameter / 3) + y
  let gab = unit_size / 16

  // create square -->
  p5.fill(color_0);
  p5.noStroke()
  p5.square(...shape)

  // create circle
  // start top left
  let start_top_left_x = start_point_circle_x
  let start_top_left_y = start_point_circle_y
  let end_top_left_x = start_point_circle_x + circle_diameter
  let end_top_left_y = start_point_circle_y + circle_diameter

  // start top right
  let start_top_right_x = start_point_circle_x + circle_diameter + gab
  let start_top_right_y = start_point_circle_y
  let end_top_right_x = start_top_right_x + (circle_diameter)
  let end_top_right_y = start_point_circle_y + circle_diameter

  // start bot left
  let start_bot_left_x = start_point_circle_x
  let start_bot_left_y = start_top_left_y + circle_diameter + gab
  let end_bot_left_x = start_point_circle_x + circle_diameter
  let end_bot_left_y = start_point_circle_y + (circle_diameter * 2) + gab

  // start bot right
  let start_bot_right_x = start_bot_left_x + circle_diameter + gab
  let start_bot_right_y = start_bot_left_y
  let end_bot_right_x = end_bot_left_x + circle_diameter + gab
  let end_bot_right_y = end_bot_left_y


  p5.fill(color_1);
  p5.noStroke();
  p5.circle(start_top_left_x + circle_diameter / 2, start_top_left_y + circle_diameter / 2, circle_diameter)

  p5.fill(color_2);
  p5.noStroke();
  p5.circle(start_top_right_x + circle_diameter / 2, start_top_right_y + circle_diameter / 2, circle_diameter)

  p5.fill(color_3);
  p5.noStroke();
  p5.circle(start_bot_left_x + circle_diameter / 2, start_bot_left_y + circle_diameter / 2, circle_diameter)

  p5.fill(color_4);
  p5.noStroke();
  p5.circle(start_bot_right_x + circle_diameter / 2, start_bot_right_y + circle_diameter / 2, circle_diameter)


}

function Gen4Point1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {

  let color_0 =  color_2nd;
  let color_1 =  color_0th;
  let color_2 =  color_0th;
  let color_3 =  color_1st;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  Gen4PointBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )

}

function Gen4Point2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {

  let color_0 =  color_3rd;
  let color_1 =  color_5th;
  let color_2 =  color_5th;
  let color_3 =  color_4th;
  let color_4 =  color_5th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  Gen4PointBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen4PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {

  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  let color_3 = color_3rd;
  let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]
  // box_size = unit_size / 2
  let circle_diameter = unit_size / 3
  let center_circle = unit_size / 4
  let start_point_circle_x = center_circle - (circle_diameter / 3) + x
  let start_point_circle_y = center_circle - (circle_diameter / 3) + y
  let gab = unit_size / 16

  // create square -->
  p5.fill(color_0);
  p5.noStroke()
  p5.square(...shape)

  let box_size = unit_size / 2

  let padding = unit_size / 40

  //### start top left
  let st_top_left_x = 0 + padding + x
  let st_top_left_y = (box_size / 2) + y
  let nd_top_left_x = box_size + x
  let nd_top_left_y = 0 + y
  let rd_top_left_x = box_size + x
  let rd_top_left_y = box_size + y

  //### start top right
  let st_top_right_x = 0 + box_size + x
  let st_top_right_y = (box_size / 2) + y
  let nd_top_right_x = box_size + box_size + x
  let nd_top_right_y = 0 + y
  let rd_top_right_x = box_size + box_size + x
  let rd_top_right_y = box_size + y

  //### start bot left
  let st_bot_left_x = 0 + padding + x
  let st_bot_left_y = (box_size / 2) + box_size + y
  let nd_bot_left_x = box_size + x
  let nd_bot_left_y = 0 + box_size + y
  let rd_bot_left_x = box_size + x
  let rd_bot_left_y = box_size + box_size + y

  //### start bot right
  let st_bot_right_x = 0 + box_size + x
  let st_bot_right_y = (box_size / 2) + box_size + y
  let nd_bot_right_x = box_size + box_size + x
  let nd_bot_right_y = 0 + box_size + y
  let rd_bot_right_x = box_size + box_size + x
  let rd_bot_right_y = box_size + box_size + y



  p5.fill(color_1);
  p5.noStroke();
  p5.triangle(st_top_left_x, st_top_left_y,
    nd_top_left_x, nd_top_left_y,
    rd_top_left_x, rd_top_left_y)

  p5.fill(color_2);
  p5.noStroke();
  p5.triangle(st_top_right_x, st_top_right_y,
    nd_top_right_x, nd_top_right_y,
    rd_top_right_x, rd_top_right_y)

  p5.fill(color_3);
  p5.noStroke();
  p5.triangle(st_bot_left_x, st_bot_left_y,
    nd_bot_left_x, nd_bot_left_y,
    rd_bot_left_x, rd_bot_left_y)

  p5.fill(color_4);
  p5.noStroke();
  p5.triangle(st_bot_right_x, st_bot_right_y,
    nd_bot_right_x, nd_bot_right_y,
    rd_bot_right_x, rd_bot_right_y)


}

function Gen4Polygons1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  
  let color_0 =  color_1st;
  let color_1 =  color_0th;
  let color_2 =  color_0th;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  Gen4PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen4Polygons2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  
  let color_0 =  color_3rd;
  let color_1 =  color_5th;
  let color_2 =  color_5th;
  let color_3 =  color_5th;
  let color_4 =  color_5th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  Gen4PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen4RectanglesBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  let color_3 = color_3rd;
  let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]


  let box_size = unit_size / 2

  // create square -->
  p5.fill(color_0);
  p5.noStroke()
  p5.square(...shape)



  // ### start top left
  let st_top_left_x = 0 + x
  let st_top_left_y = 0 + y
  let nd_top_left_x = box_size + x
  let nd_top_left_y = box_size + y

  // ### start top right
  let st_top_right_x = 0 + box_size + x
  let st_top_right_y = 0 + y
  let nd_top_right_x = box_size + box_size + x
  let nd_top_right_y = box_size + y


  // ### start bot left
  let st_bot_left_x = 0 + x
  let st_bot_left_y = 0 + box_size + y
  let nd_bot_left_x = box_size + x
  let nd_bot_left_y = box_size + box_size + y


  // ### start bot right
  let st_bot_right_x = 0 + box_size + x
  let st_bot_right_y = 0 + box_size + y
  let nd_bot_right_x = box_size + box_size + x
  let nd_bot_right_y = box_size + box_size + y


  p5.fill(color_1);
  p5.noStroke();
  p5.rect(st_top_left_x, st_top_left_y,
    box_size, box_size)


  p5.fill(color_2);
  p5.noStroke();
  p5.rect(st_top_right_x, st_top_right_y,
    box_size, box_size)

  p5.fill(color_3);
  p5.noStroke();
  p5.rect(st_bot_left_x, st_bot_left_y,
    box_size, box_size)


  p5.fill(color_4);
  p5.noStroke();
  p5.rect(st_bot_right_x, st_bot_right_y,
    box_size, box_size)

}

function Gen4Rectangles1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  
  let color_0 =  color_1st;
  let color_1 =  color_5th;
  let color_2 =  color_3rd;
  let color_3 =  color_3rd;
  let color_4 =  color_5th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen4RectanglesBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen4Rectangles2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  
  let color_0 =  color_0th;
  let color_1 =  color_0th;
  let color_2 =  color_1st;
  let color_3 =  color_1st;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen4RectanglesBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1, color_2, color_3, color_4, color_5 )
}

function Gen2PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  let color_3 = color_3rd;
  let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]


  // create square -->
  p5.fill(color_0);
  p5.noStroke()
  p5.square(...shape)


  p5.fill(color_1);
  p5.noStroke();
  p5.triangle(x, y,
    x, unit_size + y,
    unit_size + x, unit_size + y)



}

function Gen2Polygons1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_2nd;
  let color_1 =  color_5th;
  let color_2 =  color_0th;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen2PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen2Polygons2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_2nd;
  let color_1 =  color_0th;
  let color_2 =  color_0th;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen2PolygonsBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen2CirclesBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  let color_3 = color_3rd;
  let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size
  let center_point = unit_size / 2
  let shape = [x, y, unit_size]
  let w_e = (unit_size) / 2.5
  let h_e = (unit_size) / 2.5
  let shape_ellipse = [center_point + x, center_point + y, w_e - 0, h_e - 0]


  // create square -->
  p5.fill(color_0)
  p5.noStroke()
  p5.square(...shape)


  p5.fill(color_1)
  p5.stroke(color_2)
  p5.strokeWeight(unit_size / 10)
  p5.ellipse(...shape_ellipse)

}

function Gen2Circles1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_1st;
  let color_1 =  color_0th;
  let color_2 =  color_2nd;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  Gen2CirclesBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}


function Gen2Circles2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_3rd;
  let color_1 =  color_5th;
  let color_2 =  color_2nd;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  Gen2CirclesBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function GenSemiCirclesBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  let color_3 = color_3rd;
  let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]
  let center = unit_size / 2
  let left_point_x = center + x
  let left_point_y = center + y

  // create square -->
  p5.fill(color_0)
  p5.noStroke()
  p5.square(...shape)

  p5.fill(color_1)
  p5.stroke(color_1)
  p5.noStroke()
  p5.arc(left_point_x, left_point_y, center, center, p5.radians(45), p5.radians(225))

  p5.fill(color_2)
  p5.stroke(color_2)
  p5.noStroke()
  p5.arc(left_point_x, left_point_y, center, center, p5.radians(225), p5.radians(45))

}

function GenSemiCircles1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_2nd;
  let color_1 =  color_5th;
  let color_2 =  color_3rd;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;
  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  GenSemiCirclesBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function GenSemiCircles2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 =  color_2nd;
  let color_1 =  color_0th;
  let color_2 =  color_3rd;
  let color_3 =  color_0th;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 

  GenSemiCirclesBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}


function Gen2PolygonsRectangleBase(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ) {
  let color_0 = color_0th;
  let color_1 = color_1st;
  let color_2 = color_2nd;
  //let color_3 = color_3rd;
  //let color_4 = color_4th;
  //let color_5 = color_5th;
  let x = props.x + (x_axis * props.unit_size)
  let y = props.y + (y_axis * props.unit_size)

  var unit_size = unit_size

  let shape = [x, y, unit_size]

  let start_point = (unit_size) / 4
  let square_size = (unit_size) / 2

  // create square -->
  p5.fill(color_0)
  p5.noStroke()
  p5.square(...shape)

  p5.fill(color_1);
  p5.noStroke();
  p5.triangle(x, y, x, y + unit_size, x + unit_size, y + unit_size)

  p5.fill(color_2)
  p5.noStroke()
  p5.square(x + start_point, y + start_point, square_size)


}

function Gen2PolygonsRectangle1st(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ){
  let color_0 =  color_2nd;
  let color_1 =  color_1st;
  let color_2 =  color_0th;
  let color_3 =  color_1st;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen2PolygonsRectangleBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}

function Gen2PolygonsRectangle2nd(props, p5, x_axis, y_axis, unit_size, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th ){
  let color_0 =  color_2nd;
  let color_1 =  color_3rd;
  let color_2 =  color_5th;
  let color_3 =  color_1st;
  let color_4 =  color_0th;

  let color_5 =  color_5th;

  // 0 = 0
  // 1 = 0
  // 2 = 1
  // 3 = 2
  // 4 = 3
  // 5 = 4
  // 6 = 5 
  Gen2PolygonsRectangleBase(props, p5, x_axis, y_axis, unit_size, color_0, color_1, color_2, color_3, color_4, color_5 )
}


function GenArt(props) {

  const [dataMap, setDataMap] = useState();
  const [dataPaddingMap, setDataPaddingMap] = useState();
  const [funcListAll, setFuncListAll] = useState();
  const [colorListAll, setColorListAll] = useState();
  const [colorIndex,setColorIndex]= useState(Math.round(Math.random() * 4));
  //const [colorIndex,setColorIndex]= useState(0);

  const [unitSize,setunitSize]= useState(props.unit_size);

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(1920, 2560).parent(canvasParentRef)
    p5.background(255, 255, 200)
    const [dataMapTmp, dataPaddingMapTmp] = GenArea()
    setDataMap(dataMapTmp)
    setDataPaddingMap(dataPaddingMapTmp)

    console.log(dataMapTmp)
    console.log(dataMapTmp.length+" x")
    console.log(dataMapTmp[0].length+" y")
    console.log(dataPaddingMapTmp)
    console.log(dataPaddingMapTmp.length+" x")
    console.log(dataPaddingMapTmp[0].length+" y")

  

    let tmpFuncListAll = [
      Gen4Point1st,
      Gen4Polygons1st,
      Gen4Rectangles1st,
      Gen4Point2nd,
      Gen2Polygons1st,
      GenSemiCircles1st,
      Gen2Circles1st,
      Gen2Circles2nd,
      Gen2Polygons2nd,
      GenSemiCircles2nd,
      Gen4Rectangles2nd,
      Gen2PolygonsRectangle1st,
      Gen2PolygonsRectangle2nd,
      Gen4Polygons2nd
    ]

    let tmpColorListAll = [
      ["#FFFFFF","#6871fc","#424cf9", "#393ddc", "#261ea7", "#12006c"],
      ["#FFFFFF","#c57b57","#f1ab86", "#f7dba7", "#9cafb7", "#251605"],
      ["#FFFFFF","#069e2d","#058e3f", "#04773b", "#036016", "#03440c"],
      ["#FFFFFF","#dbc2cf","#9fa2b2", "#3c7a89", "#2e4756", "#16262e"],
      ["#FFFFFF","#080f0f","#a4bab7", "#eff2c0", "#bea57d", "#a52422"]  
      
    ]


    setFuncListAll(tmpFuncListAll)
    setColorListAll(tmpColorListAll)

  }

  const draw = (p5) => {
    p5.noLoop()
    var x_axis = 0;
    var y_axis = 0;

    var color_1st = colorListAll[colorIndex][1]
    var color_2nd = colorListAll[colorIndex][2]
    var color_3rd = colorListAll[colorIndex][3]
    var color_4th = colorListAll[colorIndex][4]
    var color_5th = colorListAll[colorIndex][5]
    var color_0th = colorListAll[colorIndex][0]

    //console.log(dataMap)
    //console.log(dataPaddingMap)

    // GEN ART
    // console.log(dataMap)
    // console.log(dataPaddingMap)
 
    for (var x_axis = 0; x_axis < (dataPaddingMap.length ); x_axis++) {
      //console.log(dataPaddingMap[x])

      for (var y_axis = 0; y_axis < (dataPaddingMap[x_axis].length ); y_axis++) {
          //console.log("----")
          //console.log(dataPaddingMap[x][y])
          if (
              (dataPaddingMap[x_axis][y_axis] == 0) 
          ) {
                
            funcListAll[ dataMap[x_axis][y_axis]](props, p5, y_axis, x_axis, unitSize, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th );
          }
          else if(dataPaddingMap[x_axis][y_axis] == 1)  {
            // Big size
            funcListAll[ dataMap[x_axis][y_axis]](props, p5, y_axis, x_axis, unitSize*2, color_0th, color_1st, color_2nd, color_3rd, color_4th, color_5th );
          }



      }
    }

  }

  return (
    <React.Fragment>
      <Sketch setup={setup} draw={(draw)} />
    </React.Fragment>
  )

}

GenArt.defaultProps = {
  x: 0,
  y: 0,
  unit_size: 320,
  color_0th: "#000000",
  color_1st: "#F08210",
  color_2nd: "#FF006c",
  color_3rd: "#FF00FF",
  color_4th: "#FF0",
  color_5th: "#FF0"

}

export default GenArt

